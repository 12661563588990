.header-view {
  //   border-bottom: 1px solid #2f2d6c;

  .header {
    width: 100%;
    height: 70px;

    .logo {
      margin-left: 16px;
    }

    .setting {
      margin-right: 32px;
    }
  }
}

.seting {
  cursor: pointer;
}

.menuList {
  margin-left: 60px;

  .menuItem {
    color: rgb(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    height: 100%;

    &:hover {
      cursor: pointer;
      color: #eef2f9;
    }
  }

  .menuItemActive {
    color: #eef2f9;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      bottom: 0px;
      right: 0;
      left: 0;
      top: 0;
      margin: 0 auto;
      bottom: 4rem;
      height: 0.4rem;
      width: 2rem;
      transform: translateY(4.7rem);
      background: linear-gradient(180deg, #ffd100 0%, #f89542 100%);
    }
  }
}

.MuiSvgIcon-root {
  width: auto !important;
  height: auto !important;
}

.switchBtn {}

.connect_esbt {
  background: #2f2d6c;
  border: 1px solid #9b96ff;
  border-radius: 4px;
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: -8px;
  width: 38px;
  height: 16px;
  line-height: 1.1;
  color: #9b96ff;
}

.switchBtn,
.connectBtn {
  position: relative;

  &:hover {
    .downIcon {
      path {
        fill: rgba(238, 242, 249, 0.5);
      }
    }
  }
}

.downIcon {
  path {
    fill: rgba(238, 242, 249, 0.5);
  }
}

.setting {
  &:hover {
    .downIcon {
      path {
        fill: #ffffff;
      }
    }
  }
}

.setting {
  width: 35px;
  height: 35px;
  border: 1px solid #9b96ff;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #4540aa;
    border: 1px solid #4540aa;
  }
}

.getBtnBox,
.switchBtnBox,
.connectBtnBox {
  height: 36px;

  .MuiButton-root {
    line-height: 36px;
    width: 100%;
    max-width: 100%;
    border-radius: 9px;
    font-size: 13px;
    font-weight: 700;
    font-family: inherit;
  }
}

.getBtnBox {
  .MuiButton-contained {
    background-color: #f89542;
    color: #ffffff;
  }

  .MuiButton-contained.Mui-disabled {
    background-color: transparent;
    color: #f89542;
    border: 1px solid #f89542;
  }
}

.switchBtnBox,
.connectBtnBox {
  .MuiButton-contained {
    position: relative;
    color: #eef2f9;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background-color: transparent;

    &:hover {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.16);
      color: #ffffff;
    }
  }
}

.getBtnBox {
  .MuiButton-contained:hover {
    background-color: #ffaf6b;
  }
}

.MuiDrawer-paper {
  width: auto !important;
}

.drawerBg {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(24px);

  .drawerClose {
    margin-top: 30px;
    margin-left: 22px;
  }
}

@media only screen and (max-width: 1490px) {
  .menuList {
    margin-left: 32px;

    .menuItem {}
  }
}

@media only screen and (max-width: 1280px) {
  .header-view {
    .header {
      .connectBtn {
        margin-right: 24px;
      }
    }
  }

  .menuList {
    margin-top: 38px;
    margin-left: 16px;
    gap: 32px;

    .menuItem {
      position: relative;
      font-size: 24px;

      &.menuItemActiveh5 {
        border-left: 4px solid transparent;
        font-size: 24px;
        padding-left: 2px;

        &::after {
          position: absolute;
          content: " ";
          top: 0.3rem;
          left: -0.6rem;
          height: 1.8rem;
          width: 0.4rem;
          transform: translateY(0);
          background: linear-gradient(180deg, #ffd100 0%, #f89542 100%);
        }
      }
    }
  }

  .getBtnBox {
    margin-top: 30px;
  }

  .drawerBg {

    .getBtnBox,
    .switchBtnBox,
    .connectBtnBox {}

    .connectBtnBox5 {
      color: rgba(238, 242, 249, 0.5);
      margin: 0 !important;
    }

    .switchBtnBox,
    .connectBtnBox {}

    .h5sai {
      overflow-y: scroll;
    }

    .linkList {
      height: 15rem;
      background: rgba(255, 255, 255, 0.05);

      .linkItem {
        &:not(:first-child) {
          margin-left: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .header-view {
    .header {
      .logo {
        margin-left: 10px;
      }

      .connectBtn {
        margin-right: 16px;
      }
    }
  }
}
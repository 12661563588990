.footer-view {
    margin-top: 40px;
    border-top: 1px solid #2F2D6C;

    .container2 {
        margin-top: 40px;
        margin-bottom: 36px;

        .linkList1,
        .linkList2 {
            .linkItem {
                &:not(:first-child) {
                    margin-left: 100px;
                }
            }
        }

        .linkList2 {
            margin-left: 100px;
        }
    }
}

@media only screen and (max-width: 1080px) {
    .footer-view {
        .container2 {
            .linkList1 {
                margin-top: 48px;
            }

            .linkList2 {
                margin-top: 44px;
                margin-left: 0;

                .linkItem {
                    &:not(:first-child) {
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}